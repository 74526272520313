#cesiumContainer{
    width: 100%;
    height: 100vh;
    position: relative;
}
#toolbar {
  position: absolute;
  top: 50px;
  width: 200px;
  height: 45px;
  margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
  z-index: 10000;
  background: rgba(42,42,42,0.7);
  padding: 10px;
  color: white;
  border-radius: 5px;
  border: 1px solid #444;
  cursor: pointer;
}
#toolbarPanel{
    
    position: absolute;
    
    width: 290px;
    top: 50px;
    
    z-index: 10000;
    background: rgba(42,42,42,0.7);
    padding: 10px;
    color: white; 
    border-radius: 5px;
    border: 1px solid #444;
}
#legend{
    display: none;
    overflow: visible;
    width: 200px;
    font-size: 14px;
    position: absolute;
    top: 232px;
    
    
    
    z-index: 10000;
}
.backdrop {
    display: inline-block;
    background: rgba(42,42,42,0.7);
    border-radius: 5px;
    border: 1px solid #444;
    padding: 5px 10px;
    color: #fff;
    line-height: 150%;
}

.legend-colorBlock {
    display: inline-block;
    width: 12px;
    height: 12px;
    border: #444 solid thin;
    vertical-align: middle;
    margin: 2px 5px 2px 0;
}
.nowrap {
    white-space: nowrap;
}
#pallet-picker-label {
    float: right;
}
#palette-picker-container {
    width: 50px;
    height: 12px;
    display: inline-block;
}
.palette-picker {
    width: 100%;
    height: 100%;
    border: 1px solid black;
    cursor: pointer;
}
.palette-picker-dropDown {
    display: none;
    position: absolute;
    top: auto;
    width: 320px;
    max-height: 500px;
    margin-top: 5px;
    background-color: rgba(38,38,38,0.75);
    border: 1px solid #444;
    padding: 6px;
    overflow: auto;
    border-radius: 10px;
    flex-wrap: wrap;
}
.palette-picker-dropDown-visible {
    display: flex;
}
.palette-picker-choice {
    display: inline-block;
    border: 1px solid black;
    vertical-align: top;
    margin: 2px 5px;
    width: 50px;
    height: 12px;
    text-align: center;
    cursor: pointer;
}
.cesium-viewer-toolbar {
    top: 50px !important;
    right: 15px !important;
}
#heightSliderLabel, #heightValue {
    vertical-align: top;
}

#heightSlider{
    margin-top: 3px;
    margin-left: 3px;
}
#resolutionSliderLabel {
    vertical-align: top;
}

#resolutionSlider{
    margin-top: 3px;
    margin-left: 3px;
}
#styleConfig{
    font-size: small;
}
#resolutionValue{
    text-align: center;
    color: black;
    width: 35px;
}

#HideDistanceLabel {
    vertical-align: top;
}

#HideDistance{
    margin-top: 3px;
    margin-left: 15px;
}